.Soundbyte {
  margin-right: 16px;

  svg {
    width: 26px;
  }

  .pause {
    display: none;
  }

  .icon-white,
  .icon-black {
    pointer-events: none;
  }

  .icon-white {
    display: none;
  }

  &.playing {
    .pause {
      display: block;
    }

    .play {
      display: none;
    }
  }

  .theme-dark & {
    .icon-white {
      display: flex;
    }

    .icon-black {
      display: none;
    }
  }

  .theme-light & {
    .icon-white {
      display: none;
    }

    .icon-black {
      display: flex;
    }
  }
}

.copy {
  .sound-title {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;

    .Soundbyte {
      margin-right: 16px;

      & + .title {
        margin: 3px 0 0 0;
      }
    }
  }
}
