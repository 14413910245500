.EventsPage {
  min-height: 100vh;
  width: 100%;
  padding-top: 120px;

  background-color: $color-white;
  color: $color-black;

  .close-overlay-button {
    color: $color-black;
  }

  &.theme-dark {
    color: $color-white;

    .close-overlay-button {
      color: $color-white;
    }
  }

  .top {
    text-align: center;
    margin-bottom: 50px;

    .headline {
      text-align: center;
      margin-bottom: 25px;
    }

    .description {
      max-width: 1340px;
      text-align: center;
      margin-bottom: 30px;
    }

    .wrapper {
      grid-column-start: 2;
      grid-column-end: span 10;
    }
  }

  .events {
    padding-bottom: 87px;

    //grid-column-start: 2;
    grid-column-end: span 12;

    .date-title,
    .time-title,
    .event-title {
      grid-column-end: span 2;
      margin-bottom: 30px;
    }

    &.past .date-title {
      grid-column-end: span 12;
    }

    .date-title {
      @media (min-width: $media-breakpoint-md) {
        grid-column-start: 2;
      }
    }

    &.active .date-title,
    .time-title,
    .event-title {
      display: none;
    }

    .event-title {
      grid-column-end: span 6;
    }

    .event {
      //border-top: 1px solid #979797;
      //grid-column-start: 2;
      grid-column-end: span 12;
      padding-left: 0;
      padding-right: 0;
      position: relative;
      display: grid;
      padding-bottom: 35px;

      .divider {
        //grid-column-start: 2;
        //grid-column-end: span 10;
        grid-column-end: span 12;
        background-color: #979797;
        height: 1px;
        width: 100%;
        grid-column-start: 1;
        grid-column-end: span 12;
        margin-bottom: 20px;

        @media (min-width: $media-breakpoint-md) {
          grid-column-start: 2;
          grid-column-end: span 10;
          margin-bottom: 25px;
        }

        @media (min-width: $media-breakpoint-xxl) {
          margin-bottom: 35px;
        }
      }

      .event-date {
        grid-column-end: span 12;
        //grid-column-end: span 2;
        //grid-column-start: 2;
        @extend .type-title--small;
        margin-bottom: 25px;

        p {
          @extend .type-title--small;
        }

        br {
          display: none;
        }
      }

      .event-entries {
        grid-column-end: span 12;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 0;
      }

      .event-time {
        grid-column-end: span 4;

        @media (min-width: $media-breakpoint-md) {
          grid-column-end: span 6;
        }

        p {
          @extend .type-title--body;
          font-weight: 300;
        }
      }

      .event-event {
        grid-column-end: span 5;

        &:not(:last-of-type) {
          margin-bottom: 14px;
        }

        @extend .type-title--body;

        p {
          @extend .type-title--body;
        }
      }

      .event-link {
        white-space: nowrap;
        grid-column-end: span 2;
        grid-column-start: 10;
        position: relative;

        .label {
          display: none;
        }

        .arrow {
          position: absolute;
          right: 0;
          top: -5px;
          transform: rotate(-90deg);
        }

        polygon {
          fill: $color-cta;
        }

        p {
          @extend .type-title--body;
        }

        a {
          text-align: right;
        }

        a:hover {
          p {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .past {
    padding-bottom: 180px;
    display: none;

    .event {
      opacity: 0.3;
    }
  }

  @media (min-width: $media-breakpoint-md) {
    padding-top: 160px;

    .events {
      padding-bottom: 100px;

      .date-title {
        grid-column-start: 2;
      }

      .date-title,
      .time-title,
      .event-title {
        margin-bottom: 50px;
      }

      &.active .date-title,
      .time-title,
      .event-title {
        display: block;
      }

      .event {
        .event-entries {
          grid-column-end: span 7;
          grid-template-columns: repeat(7, 1fr);
          column-gap: 0;
        }

        .event-event {
          grid-column-end: span 4;

          &:not(:last-of-type) {
            margin-bottom: 16px;
          }
        }

        .event-time {
          grid-column-end: span 2;
          grid-column-start: 1;
        }

        .event-date {
          margin-bottom: 0;
          grid-column-end: span 2;
          grid-column-start: 2;

          br {
            display: block;
          }
        }

        .read-more {
          position: relative;
          top: unset;
          right: unset;
        }
      }
    }

    .top {
      margin-bottom: 80px;

      .headline {
        margin-bottom: 30px;
      }

      .description {
        margin-bottom: 35px;
      }
    }
  }

  @media (min-width: $media-breakpoint-lg) {
    padding-top: 220px;

    .events {
      padding-bottom: 140px;

      .event {
        .event-entries {
          grid-column-end: span 8;
          grid-template-columns: repeat(8, 1fr);
        }

        .event-event {
          grid-column-start: 3;

          &:not(:last-of-type) {
            margin-bottom: 23px;
          }
        }

        .event-link {
          grid-column-end: span 2;
          grid-column-start: 7;

          .label {
            display: block;
            color: $color-cta;
          }

          .arrow {
            display: none;
          }
        }
      }
    }
  }

  @media (min-width: $media-breakpoint-xl) {
    padding-top: 250px;

    .events {
      padding-bottom: 168px;

      .event {
        .event-event {
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
      }
    }

    .top {
      margin-bottom: 120px;

      .headline {
        margin-bottom: 60px;
      }

      .description {
        margin-bottom: 50px;
      }
    }
  }

  @media (min-width: $media-breakpoint-xxl) {
    .events {
      .event {
        .event-event {
          &:not(:last-of-type) {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}
